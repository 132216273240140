<script setup lang="ts">
import Clinic from './facilityType/Clinic.vue'
import Dermatology from './facilityType/Dermatology.vue'
import Surgery from './facilityType/Surgery.vue'
import Dentistry from './facilityType/Dentistry.vue'
import Hairremoval from './facilityType/Hairremoval.vue'
import Aga from './facilityType/Aga.vue'

type Props = {
  facilityTypeSlug?: string
}
const props = withDefaults(defineProps<Props>(), {
  facilityTypeSlug: '',
})
const components: { [key: string]: typeof Clinic } = {
  clinic: Clinic,
  dermatology: Dermatology,
  surgery: Surgery,
  dentistry: Dentistry,
  hairremoval: Hairremoval,
  aga: Aga,
}
</script>
<template>
  <component :is="components[props.facilityTypeSlug]" />
</template>
<scss lang="ts" scoped></scss>
